<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Online markers</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Online marker
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn  @click="createOnlineMarker()" class="btn btn-primary mr-5" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new
                  </v-btn>
                  <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="addFilter()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-filter"></i>
                                </span>
                          <span class="navi-text">Filters</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-excel"></i>
                                </span>
                          <span class="navi-text">Export (excel)</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                          <span class="navi-text">Export (CSV)</span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="showSelectField()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-database"></i>
                                </span>
                          <span class="navi-text">Export fields</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            
            <div v-if="customFilters.length > 0" class="mb-7">
              <a href="javascript:;" @click="showSavedSearch = !showSavedSearch" class="btn btn-primary text-white"> <i class="fa fa-info"></i> Saved search </a>
              <div class="mt-2" v-if="showSavedSearch">
                <span v-for="(item, idx) in customFilters" class="d-block">
                  <i class="fa fa-clock-o"></i> {{item.created_at_formatted}}, 
                  <strong>Type: </strong> {{item.is_private ? 'Personal' : 'All'}}, 
                  <strong>Name : </strong> <a href="javascript:;" @click="openSavedSearch(item.id)" class="font-weight-bold"> {{item.name}} </a>
                  <a href="javascript:;" @click="deleteCustomFilter(item.id)" class="ml-3"> <i class="fa fa-trash blue--text"></i> </a>
                </span>
              </div>
            </div>

            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" sm="12" md="3">
                  <v-text-field 
                    label="Lookup by name, unique IDs, email or phone"
                    v-model="search.info"
                    @input="search.info = $event !== null ? $event : ''"
                    v-on:keyup.enter="searchOnlineMarker" 
                    outlined 
                    dense 
                    clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchOnlineMarker"
                      @input="search.first_name = $event !== null ? $event : ''"
                      label="First name"
                      v-model="search.first_name" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchOnlineMarker"
                      @input="search.last_name = $event !== null ? $event : ''"
                      label="Last name"
                      v-model="search.last_name" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchOnlineMarker"
                      @input="search.email = $event !== null ? $event : ''"
                      label="Email"
                      v-model="search.email" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" :md="currentUser.access_type != 'score' ? 3 : 4">
                  <v-text-field  
                    v-on:keyup.enter="searchOnlineMarker"
                    @input="search.mobile = $event !== null ? $event : ''"
                    label="Mobile No."
                    v-model="search.mobile" 
                    outlined 
                    dense 
                    clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="3" v-if="currentUser.access_type!='score'">
                  <v-select
                      label="SCORE"
                      v-model="search.current_score_id"
                      v-on:keyup.enter="searchOnlineMarker"
                      @input="search.current_score_id = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="id" 
                      :items="scores"
                      outlined 
                      dense 
                      clearable
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" :md="currentUser.access_type != 'score' ? 3 : 4">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value" clearable
                      outlined v-on:keyup.enter="searchOnlineMarker"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" :md="currentUser.access_type == 'score' ? 6 : 3"  class="text-right">
                  <v-btn :loading="isLoading"
                      @click="searchOnlineMarker"
                      class="btn btn-primary btn-search"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

                <v-col v-if="this.filters[0].search_fields != ''" cols="12" md="2">
                  <v-btn
                      @click="saveLink('private')"
                      class="mt-1 btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-save</v-icon>&nbsp;
                    Save for me
                  </v-btn>
                </v-col>

                <v-col v-if="this.filters[0].search_fields != ''" cols="12" md="2">
                  <v-btn
                      @click="saveLink('all')"
                      class="mt-1 btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-save</v-icon>&nbsp;
                    Save for all
                  </v-btn>
                </v-col>

              </div>
            </div>

            <div class="row mb-2" v-if="search.search_fields.length != 0">
              <v-col cols="12" md="12">
                <div class="subtitle font-weight-bold">
                  Filter applied
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <div class="w-full">
                      <v-btn class="mr-2 mb-2" color="primary"
                          v-for="(item, index) in search.search_fields"
                          v-if="item"
                          :key="index"
                      >
                        {{ formatFilterName(item)}}
                      </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <v-btn
                    @click="resetAppliedFilter"
                    color="black"
                    outlined
                >Reset filter
                </v-btn>
              </v-col>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Surname</th>
                  <th class="px-3">First name</th>
                  <th class="px-3">Unique IDs </th>
                  <th style="max-width: 200px !important; white-space: pre-wrap;" class="px-3">Email</th>
<!--                  <th class="px-3">Candidate</th>-->
                  <th class="px-3">Phone/mobile</th>
                  <th style="max-width: 200px !important; white-space: pre-wrap;" class="px-3">SCORE</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="online_markers.length > 0" v-for="item in online_markers">
                    <td class="px-3">

                      <a @click="editOnlineMarker(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.last_name }}
                      </a>
                      <div>
<!--                        <span class="badge badge-role mr-1 " v-if="item.role_text">-->
<!--                          {{ item.role_text}}-->
<!--                        </span>-->
                        <span class="badge"
                              v-bind:class="{ 'badge-success': item.is_active_online_marker, 'badge-danger': !item.is_active_online_marker }"
                        >{{ item.online_marker_status_text }}</span>
                      </div>
                    </td>
                    <td>
                      <span class="mb-1" v-copy="item.first_name">
                        {{item.first_name }}
                      </span>
                    </td>
                    <td>
                      <span  class="" @click="editAccountHolder(item.id)" v-copy="item.unique_identifier">
                        {{item.unique_identifier ? item.unique_identifier : 'NA' }}
                      </span>
                    </td>
                    <td style="max-width: 200px !important; white-space: pre-wrap;">
                      <a  class="text-info mb-1" :href="'mailto:'+item.email">
                        {{item.email ? item.email : 'NA' }}
                      </a>
                    </td>
                    <td class="text-left">
                      <a  class="text-info mb-1" :href="'tel:'+item.preferred_phone">
                      {{item.preferred_phone}}
                      </a>
                    </td>

                    <td style="max-width: 200px !important; white-space: pre-wrap;">
                      {{item.score_short_name ? item.score_short_name : 'NA' }}
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

<!--                            <b-dropdown-text tag="div" class="navi-item">-->
<!--                              <a @click.prevent="openQuickView(item.id)" class="navi-link">-->
<!--                                <span class="navi-icon">-->
<!--                                      <i class="fa fa-eye"></i>-->
<!--                                </span>-->
<!--                                <span class="navi-text">Quick view</span>-->

<!--                              </a>-->
<!--                            </b-dropdown-text>-->

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editOnlineMarker(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit online marker</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="openResetPassword(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-power-off"></i>
                                </span>
                                <span class="navi-text">Reset password</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="loginAsOnlineMarker(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-user-lock"></i>
                                </span>
                                <span class="navi-text">Log in as online marker</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="deleteOnlineMarker(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="online_markers.length == 0">
                    <td colspan="7" class="text-center">
                      <strong>No online markers available</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                  v-if="online_markers.length > 0"
                  class="pull-right mt-7"
                  @input="getAllOnlineMarkers"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
     <create-online-marker ref="create-online-marker" @refresh="refreshAfterSave"></create-online-marker>
      <v-dialog v-model="quickViewDialog" width="600px">
        <v-card>
          <div class="card-body ">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
              <a href="#"
                 class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 ">
                <i class="flaticon-calendar-with-a-clock-time-tools mr-2 font-weight-regular"></i>
                {{onlineMarkerDetail.member_since}}</a>
            </div>
            <!--end::Toolbar-->

            <!--begin::User-->
            <div class="d-flex align-items-center">

              <div>
                <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary mb-1">
                  {{onlineMarkerDetail.title ? onlineMarkerDetail.title : ''}} {{onlineMarkerDetail.first_name ? onlineMarkerDetail.first_name : ''}} {{onlineMarkerDetail.middle_name ? onlineMarkerDetail.middle_name : ''}} {{onlineMarkerDetail.last_name ? onlineMarkerDetail.last_name : ''}}

                </a>
                <div class="font-weight-regular mb-1">
                  <strong>Email</strong> : {{onlineMarkerDetail.email}}
                </div>
                <div class="font-weight-regular mb-1">
                  <strong>Preferred phone</strong> : {{onlineMarkerDetail.preferred_phone}}
                </div>
                <div class="font-weight-regular mb-1">
                  <strong>SCORE</strong> : {{onlineMarkerDetail.score_name}}
                </div>
              </div>
            </div>
            <!--end::User-->
            <div class="separator separator-solid"></div>
            <!--begin::Contact-->
            <div class="pt-8 pb-6">

              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Phone:</span>
                <span class="font-weight-regular">{{onlineMarkerDetail.phone ? onlineMarkerDetail.phone :'NA'}}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Mobile:</span>
                <span class="font-weight-regular">{{onlineMarkerDetail.mobile ? onlineMarkerDetail.mobile :'NA'}}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Work:</span>
                <span class="font-weight-regular">{{onlineMarkerDetail.work ? onlineMarkerDetail.work :'NA'}}</span>
              </div>
            </div>
            <div class="separator separator-solid"></div>

            <div class="pt-8 pb-6">
              <div class="row">
                <div class="col-md-6" v-if="onlineMarkerDetail.shipping_address">
                  <h3 class="card-title font-weight-bolder text-dark">Postal address</h3>
                  <div class="d-flex align-items-center flex-grow-1">

                    <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                      <div class="d-flex flex-column align-items-cente py-2 w-75">
                        <a href="#" class="text-dark-75 font-weight-medium text-hover-primary font-size-lg mb-1">
                          {{onlineMarkerDetail.shipping_address.address_line_1}} <br>
                          {{onlineMarkerDetail.shipping_address.suburb ? onlineMarkerDetail.shipping_address.suburb : 'NA'}}, {{onlineMarkerDetail.shipping_address.country_id == 14 ? onlineMarkerDetail.shipping_address.state_name : onlineMarkerDetail.shipping_address.state}},{{onlineMarkerDetail.shipping_address.post_code ? onlineMarkerDetail.shipping_address.post_code : 'NA'}} <br>
                          {{onlineMarkerDetail.shipping_address.country_name ? onlineMarkerDetail.shipping_address.country_name : 'NA'}}
                        </a>
                      </div>

                    </div>
                  </div>
                  <!--end::Content-->
                </div>
                <div class="col-md-6" v-if="onlineMarkerDetail.billing_address">
                  <h3 class="card-title font-weight-bolder text-dark">Billing address</h3>
                  <div class="d-flex align-items-center flex-grow-1">

                    <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                      <div class="d-flex flex-column align-items-cente py-2 w-75">
                        <a href="#" class="text-dark-75 font-weight-medium text-hover-primary font-size-lg mb-1">
                          {{onlineMarkerDetail.billing_address.address_line_1}} <br>
                          {{onlineMarkerDetail.billing_address.suburb ? onlineMarkerDetail.billing_address.suburb : 'NA'}}, {{onlineMarkerDetail.billing_address.country_id == 14 ? onlineMarkerDetail.billing_address.state_name : onlineMarkerDetail.billing_address.state}},{{onlineMarkerDetail.billing_address.post_code ? onlineMarkerDetail.billing_address.post_code : 'NA'}} <br>
                          {{onlineMarkerDetail.billing_address.country_name ? onlineMarkerDetail.billing_address.country_name : 'NA'}}
                        </a>
                      </div>

                    </div>
                  </div>
                  <!--end::Content-->
                </div>
              </div>
            </div>


            <a @click="editOnlineMarker(onlineMarkerDetail.id)"
               class="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block">
              Profile details
            </a>
          </div>

        </v-card>
      </v-dialog>
      <v-dialog v-model="resetPasswordDialog" max-width="500">
        <v-card>
          <v-card-title>
            Reset password
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>

                <v-col cols="12" sm="6" md="12">
                  <v-switch
                      v-model="reset.auto_generate"
                      :label="`Auto generate password`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="12" v-if="!reset.auto_generate">
                  <v-text-field
                      label="Enter new password*" v-model="reset.new_password"
                      dense outlined  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                  ></v-text-field>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="btn btn-primary"
                  dark
                  medium @click="resetPassword">
                Reset password
              </v-btn>
              <v-btn
                  color="btn btn-standard"
                  dark
                  medium
                  @click="closeResetPasswordDialog">
                Cancel
              </v-btn>

            </v-card-actions>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- export fields -->
      <v-dialog
          scrollable
          v-model="searchFieldDialog"
          width="800px"
      >
        <v-card>
          <v-card-title>
            Search filters
          </v-card-title>
          <v-card-text>
            <v-row class="mt-2" v-for="(row, index) in filters" :key="index">
              <v-col cols="12" md="3">
                <v-select
                    v-model="row.search_fields"
                    :items="onlineMarkerFiltersArray"
                    item-text="text"
                    item-value="value"
                    label="Column"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                    :items="conditionFilters"
                    v-model="row.search_conditions"
                    item-text="text"
                    item-value="value"
                    label="Condition"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                    label="Value"
                    v-model="row.search_values"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                    v-if="index == filters.length-1"
                    color="blue"
                    class="text-white mr-2"
                    medium
                    @click="addFilterColumn()"
                >
                  <i class="fa fa-plus font-white"></i>
                </v-btn>
                <v-btn
                    v-if="index || (!index && filters.length > 1)"
                    @click="removeFilterColumn(index)"
                    color="red"
                    class=" text-white"
                    medium
                >
                  <i class="fa fa-times font-white"></i>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-content-end">
            <v-btn
                @click="closeSelectFieldDialog"
                text
                x-large
            >
              Cancel
            </v-btn>
            <v-btn
                @click="applyFilter"
                x-large
                dark
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog 
        scrollable 
        v-model="selectFieldDialog" 
        width="700px"
      >
        <v-card>
          <v-card-title>
            Select columns to appear on the excel file
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-for="(item, index) in onlineMarkerFilters" cols="12" md="4">
                <v-checkbox 
                  :label="index"
                  :value="item"
                  v-model="selectedFields"
                >
                </v-checkbox>  
              </v-col>   
            </v-row> 
          </v-card-text>

          <v-spacer></v-spacer>
          
          <v-card-actions class="d-flex justify-content-end">
            <v-btn
              @click="selectFieldDialog = false;"
              class="btn btn-primary text-white"
            >
              Select
            </v-btn>
            <v-btn
              @click="closeExcelSelectFieldDialog"
              class="btn btn-standard"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        
        </v-card>
      </v-dialog>
      <!-- export field ends -->

      <!-- save search result popup -->
      <v-dialog
          scrollable
          v-model="showSaveDialog"
          width="800px"
      >
        <v-card>
          <v-card-title>
            Save search filter
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="mt-3"
                  outlined
                  dense
                  placeholder="Save search with name"
                  v-model="custom_filter.name"
                  :error="$v.custom_filter.name.$error"
                >
                  <template v-slot:label>
                    Name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.custom_filter.name.$error">
                  This information is required
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-spacer></v-spacer>

          <v-card-actions class="d-flex justify-content-end">
            <v-btn
              :loading="saveLinkLoading"
              class="btn btn-primary text-white"
              @click="saveType == 'private' ? saveLinkForMe() : saveLinkForAll()"
            >
              Save
            </v-btn>
            <v-btn
              class="btn btn-standard"
              @click="cancelSaveSearch"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- save search result popup ends -->

    </div>
  </v-app>
</template>
<script>

import OnlineMarkerService from "@/services/user/online-marker/OnlineMarkerService";
import CreateOnlineMarker from "./CreateOnlineMarker";
import ScoreService from "@/services/score/score/ScoreService";
import CustomFilterLinkService from "@/services/custom-filter-link/CustomFilterLinkService";
import {required} from "vuelidate/lib/validators";

const onlineMarker=new OnlineMarkerService();
const score = new ScoreService();
const customFilterLink = new CustomFilterLinkService();

export default {
  components:{
    CreateOnlineMarker
  },
  validations:{
    custom_filter:{
      name: {required}
    }
  },
  data(){
    return{
      isExporting:false,
      isExportingExcel: false,
      search:{
        info:'',
        first_name:'',
        last_name:'',
        is_active:'',
        score_id:'',
        type:'',
        mobile: '',
        email: '',
        selectedFields: [],
        search_fields: [],
        search_conditions: [],
        search_values: [],
        is_search: false,
      },
      selectedFields: [],
      selectedFilters: [],
      rows: [],
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      filters:[{
        search_fields:'',
        search_conditions:'',
        search_values:'',
      }],
      selectFieldDialog: false,
      searchFieldDialog: false,
      conditionFilters: [
        {text: 'Equals To', value: 'equals_to'},
        {text: 'Less Than', value: 'less_than'},
        {text: 'Less Than Equals To', value: 'less_than_equals_to'},
        {text: 'Greater Than', value: 'greater_than'},
        {text: 'Greater Than Equals To', value: 'greater_than_equals_to'},
        {text: 'Contains', value: 'contains'},
      ],
      quickViewDialog:false,
      onlineMarkerDetail:{},
      online_markers:[],
      total: null,
      perPage: null,
      page: null,
      isLoading:false,
      resetPasswordDialog:false,
      showPassword:false,
      reset:{
        onlineMarkerId:'',
        auto_generate:true,
        new_password:'',
      },
      onlineMarkerFilters: [],
      onlineMarkerFiltersArray: [],
      scores: [],
      customFilters: '',
      saveLinkLoading: false,
      showSavedSearch: false,
      showSaveDialog: false,
      saveType: '',
      custom_filter:{
        name: ''
      }
    }
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods:{
    addFilter(){
      this.searchFieldDialog = true;
    },
    openQuickView(itemId){
      this.quickViewDialog=true;
      this.getOnlineMarkerDetail(itemId);
    },
    getOnlineMarkerDetail(itemId){
      onlineMarker
          .show(itemId)
          .then(response => {
            this.onlineMarkerDetail=response.data.onlineMaker;
          })
    },
    getAllOnlineMarkers(){
      this.isLoading = true;
      onlineMarker
          .paginate(this.search,this.page)
          .then(response => {
            this.online_markers=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {
            this.$snotify.error('Oops looks like something went wrong.');
            this.searchFieldDialog=false;
            this.isLoading = false;
          });
    },
    getAllScore() {
      score
      .getScores()
      .then(response => {
        this.scores = response.data.scores;
      });
    },
    searchOnlineMarker(){
      this.search.is_search =true;
      this.getAllOnlineMarkers();
    },
    deleteOnlineMarker(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            onlineMarker
                .delete(item)
                .then((response) => {
                  this.getAllOnlineMarkers();
                  this.$snotify.success("Online marker deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    editOnlineMarker(onlineMarkerId){
      this.$router.push({
        name: "online-marker-edit",
        params: { onlineMarkerId: onlineMarkerId }
      });
    },
    createOnlineMarker(){
      this.$refs['create-online-marker'].createUserAddress();
    },
    applyFilter(){
      this.formatExportData();
      this.getAllOnlineMarkers();
      this.searchFieldDialog=false;
    },
    formatExportData(){
      this.search.selectedFields = this.selectedFields;
      let search_conditions =[];
      let search_fields = [];
      let search_values = [];

      this.filters.forEach((filter)=>{
          if(filter.search_conditions)
            search_conditions.push(filter.search_conditions);
          if(filter.search_fields)
            search_fields.push(filter.search_fields);
          if(filter.search_values)
            search_values.push(filter.search_values);
      });

      this.search.search_conditions = search_conditions;
      this.search.search_fields = search_fields;
      this.search.search_values = search_values;
    },
    refreshAfterSave(payload){
      if(payload){
        this.$router.push({
          name: "online-marker-edit",
          params: { onlineMarkerId: payload }
        });
      }
    },
    openResetPassword(onlineMarkerId){
      this.resetPasswordDialog=true;
      this.reset.onlineMarkerId=onlineMarkerId;
    },
    closeResetPasswordDialog(){
      this.resetPasswordDialog=false;
      this.reset.onlineMarkerId='';
      this.reset.auto_generate=true;
      this.reset.new_password='';
    },
    resetPassword(){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {

            this.isLoading = true;
            onlineMarker
                .resetPassword(this.reset.onlineMarkerId, this.reset)
                .then((response) => {
                  this.$snotify.success("Password reset email sent with instructions");
                  this.closeResetPasswordDialog();
                  this.isLoading = false;
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        }
      })
    },
    exportToCsv(){
      this.isExporting = true;
      this.formatExportData();

      onlineMarker
      .exportToCsv(this.search)
      .then(response => {
        this.$snotify.success('Export has been started. Once completed you can view  on notification.');
      })
      .catch(error => {

      })
      .finally(() => {
        this.isExporting = false;
        this.closeSelectFieldDialog();
      })
    },
    exportToExcel(){
      this.isExportingExcel = true;
      this.formatExportData();

      onlineMarker
      .exportToExcel(this.search)
      .then(response => {
        this.$snotify.success('Export has been started. Once completed you can view  on notification.');
      })
      .catch(error => {

      })
      .finally(() => {
        this.isExportingExcel = false;
        this.closeSelectFieldDialog();
      })
    },
    closeSelectFieldDialog(){
      this.filters = [{
        search_fields:'',
        search_conditions:'',
        search_values:'',
      }];
      this.search = {
        selectedFields: [],
        search_fields: [],
        search_conditions: [],
        search_values: [],
      }
      this.searchFieldDialog = false;
      this.getAllOnlineMarkers();
    },
    showSelectField(){
      this.selectFieldDialog = true;
    },
    closeExcelSelectFieldDialog(){
      this.selectFieldDialog = false;
      this.search.selectedFields = [];
      this.selectedFields = [];
    },
    addFilterColumn(){
      this.filters.push({
        search_fields:'',
        search_condition:'',
        search_values:'',
      });
    },
    removeFilterColumn(index){
      this.filters.splice(index, 1);
    },
    getExportFilters(){
      onlineMarker
      .getExportFilters()
      .then((response) => {
        this.onlineMarkerFilters = response.data.onlineMarkerFilters;
        let onlineMarkerFilters = response.data.onlineMarkerFilters;
        onlineMarkerFilters =  Object.entries(onlineMarkerFilters);
        
        onlineMarkerFilters.forEach(([key, value]) => {
          let data = {
            text: key,
            value: value
          };
          this.onlineMarkerFiltersArray.push(data);
        });
      }).catch((err) => {
          
      });
    },
    formatFilterName(name){
      if(name){
        name = name.replace("users^is_active","Status");
        name = name.replace("users^","");
        name = name.replace("scores^name","SCORE");

        name = name.replace("user_addresses^shipping^address_line_1","Postal address - address line 1");
        name = name.replace("user_addresses^shipping^address_line_2","Postal address - address line 2");
        name = name.replace("user_addresses^shipping^suburb","Postal address - suburb");
        name = name.replace("states^shipping^name","Postal address - state");
        name = name.replace("user_addresses^shipping^post_code","Postal address - post code");
        name = name.replace("countries^shipping^name","Postal address - country");

        name = name.replace("user_addresses^billing^address_line_1","Billing address - address line 1");
        name = name.replace("user_addresses^billing^address_line_2","Billing address - address line 2");
        name = name.replace("user_addresses^billing^suburb","Billing address - suburb");
        name = name.replace("states^billing^name","Billing address - state");
        name = name.replace("user_addresses^billing^post_code","Billing address - post code");
        name = name.replace("countries^billing^name","Billing address - country");
        
        name = name.replace(/_/g," ");
        return name.charAt(0).toUpperCase()+name.slice(1)
      }
    },
    resetAppliedFilter(){  
      this.filters = [{
        search_fields:'',
        search_conditions:'',
        search_values:'',
      }];

      this.search.search_fields = [];
      this.search.search_values = [];
      this.search.search_conditions = [];

      this.applyFilter();
    },

    loginAsOnlineMarker(onlineMarkerId)
    {
      onlineMarker.loginAsOnlineMarker(onlineMarkerId).then(response=>{
        if(response.data.status=='OK')
          window.open(response.data.url,'_blank')
        else
          this.$snotify.error("Oops something went wrong");
      }).catch(error=>{

      });
    },

    saveLink(type){
      this.saveType = type;
      this.showSaveDialog = true;
    },

    saveLinkForMe(){
      this.$v.custom_filter.$touch()
      if(this.$v.custom_filter.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else {
        this.saveLinkLoading = true;

        if(this.$route.params &&  this.$route.params.search){
          this.search['id']=this.$route.params.search;
        }
      
        let completeUrl = onlineMarker.getCompleteSearchUrl(this.search,this.page)
        let saveLinkData = {
          name: this.custom_filter.name,
          type: 'online_marker',
          is_private: 1,
          url : completeUrl,
          query_params: this.search,
          export_fields: this.selectedFields,
          user_id: this.currentUser.id,
          score_id: this.currentUser.access_type == 'score' ? this.currentUser.score_id : ''
        }
        this.saveSearchUrl(saveLinkData);
      }
    },

    saveLinkForAll(){
      this.$v.custom_filter.$touch()
      if(this.$v.custom_filter.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else {
        this.saveLinkLoading = true;

        if(this.$route.params &&  this.$route.params.search){
          this.search['id']=this.$route.params.search;
        }
        
        let completeUrl = onlineMarker.getCompleteSearchUrl(this.search,this.page)
        let saveLinkData = {
          name: this.custom_filter.name,
          type: 'online_marker',
          is_private: 0,
          url : completeUrl,
          query_params: this.search,
          export_fields: this.selectedFields,
          user_id: this.currentUser.id,
          score_id: this.currentUser.access_type == 'score' ? this.currentUser.score_id : ''
        }

        this.saveSearchUrl(saveLinkData);
      }
    },

    saveSearchUrl(data){
      
      customFilterLink
      .store(data)
      .then((res) => {
        this.$snotify.success('Search saved')
      })
      .catch((err) => {
        this.$snotify.error("Oops something went wrong");
      })
      .finally(() => {
        this.saveLinkLoading = false;
        this.cancelSaveSearch();
        this.getAllCustomFilterLinks();
      })
    },
    cancelSaveSearch(){
      this.showSaveDialog = false;
      this.custom_filter = {
        name: ''
      };
    },
    getAllCustomFilterLinks(){
      
      let filter = {
        type: 'online_marker',
      };

      customFilterLink
      .all(filter)
      .then((res) => {
        this.customFilters = res.data.data;
      })
      .catch((err) => {
        
      })
    },
    deleteCustomFilter(searchId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            customFilterLink
            .delete(searchId)
            .then((response) => {
              this.getAllCustomFilterLinks();
              this.$snotify.success("Filter deleted");
            })
            .catch((err) => {
              this.$snotify.error("Something went wrong");
            });
          }
        }
      })
    },
    openSavedSearch(searchId){
      this.resetAppliedFilter();

      customFilterLink
      .show(searchId)
      .then((res)=>{        
        this.filters = res.data.data.formatted_query_params;
        this.selectedFields = res.data.data.export_fields_formatted;
        this.applyFilter();
      })
      .catch((err) => {

      });
    }
  },
  mounted() {
    this.getAllOnlineMarkers();
    this.getAllScore();
    this.getExportFilters();
    this.getAllCustomFilterLinks();
  }
}
</script>